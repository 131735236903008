<template>
  <router-view/>
</template>
<script>
//axios 전역으로 선언
import { _axios } from '@/plugins/axios';
import { provide } from 'vue';
export default {
  name: 'App',
  setup(){
    //provide를 통해 제공하고 inject를 통해 사용
    provide('axios',_axios);
  }
}
</script>
<style lang="scss">
@import "@/assets/styles/common.scss";
@import "@/assets/styles/reset.scss";
@import "@/assets/styles/style.scss";
</style>
