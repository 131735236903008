import { createApp } from 'vue';
import App from '@/App.vue';

//vuex
import store from '@/store';

//router
import router from './router'



const app = createApp(App).use(router);
app.use(store);
app.mount('#app');
